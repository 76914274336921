@import "minima";

blockquote {
  background: $grey-color-light;
  color: $grey-color-dark;
  border-left: 4px solid $grey-color;
  padding: $spacing-unit / 2;
  @include relative-font-size(1.125);
  letter-spacing: -1px;
  font-style: italic;

  > :last-child {
    margin-bottom: 0;
  }
}

.searchpage input {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  height: 32px;
  width: 68%;
}

.searchpage button {
  float:right;
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  height: 40px;
  width: 30%;
  background-color: lighten($brand-color, 10%);
  // background-color: #5796e8;
  color: white;
  cursor: pointer;
}

.video-container {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 56.25%;
  padding-top: 30px;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.refresher-row {
  background-color: #eee;
  width: 99%;
  padding: 5px;
  margin-bottom: 10px;
  display: flex;
}

.refresher-column {
  flex: 50%;
}

.refresher-intro {
  background-color: #ddd;
  padding: 5px;
}



// .site-nav input:checked ~ .trigger > .search {
//   display: none;
// }
//
// .site-nav input ~ .trigger > .search {
//   clear: both;
//   display: block;
// }
